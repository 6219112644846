import '../App.css';
import { useEffect, useState } from 'react';
import LineChart from '../components/LineChart';
import DoughnutChart from '../components/DoughnutChart';
import { useGlobalState } from '../globalState';
import { useNavigate, Link } from 'react-router-dom';
import * as api from "../api";
import Loader from '../components/loader';
import PullToRefresh from 'react-simple-pull-to-refresh';
import * as configuration from '../config';
import PrevArrow from '../images/prev.svg';
import NextArrow from '../images/next.svg';

function dataFormat(str) {
    console.log(str);
    //return parseFloat(str.replace(/,/g, ''));
    return parseFloat(str.replace(/[\s,]/g, ''));
}

function numberWithCommas(x) {
    if (x != undefined) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

function QuarterlyTargets() {

    const [tableData3, setTableData3] = useGlobalState('tableData3');
    const [dataState, setDataState] = useState([]);

    const [yearLineChartData, setYearLineChartData] = useState({});
    const [yearLineChartData_REV, setYearLineChartData_REV] = useState({});

    const [sumYearLineChartData, setSumYearLineChartData] = useState({});
    const [sumYearLineChartData_REV, setSumYearLineChartData_REV] = useState({});

    const [dnDataTotal, setDnDataTotal] = useState({});
    const [dnOptionsTotal, setDnOptionsTotal] = useState({});

    const [dnDataTotal_REV, setDnDataTotal_REV] = useState({});
    const [dnOptionsTotal_REV, setDnOptionsTotal_REV] = useState({});

    //Direct GP
    const [direct_GP_dnDataTotal, setDirect_GP_DnDataTotal] = useState({});
    const [direct_GP_dnOptionsTotal, setDirect_GP_DnOptionsTotal] = useState({});
    //-

    //Direct TTP
    const [direct_TTP_dnDataTotal, setDirect_TTP_DnDataTotal] = useState({});
    const [direct_TTP_dnOptionsTotal, setDirect_TTP_DnOptionsTotal] = useState({});
    //-

    //BT GP
    const [bt_GP_dnDataTotal, setBT_GP_DnDataTotal] = useState({});
    const [bt_GP_dnOptionsTotal, setBT_GP_DnOptionsTotal] = useState({});
    //-

    //BT TTP
    const [bt_TTP_dnDataTotal, setBT_TTP_DnDataTotal] = useState({});
    const [bt_TTP_dnOptionsTotal, setBT_TTP_DnOptionsTotal] = useState({});
    //-

    //FlashPack GP
    const [flashPack_GP_dnDataTotal, setFlashPack_GP_DnDataTotal] = useState({});
    const [flashPack_GP_dnOptionsTotal, setFlashPack_GP_DnOptionsTotal] = useState({});
    //-

    //FlashPack TTP
    const [flashPack_TTP_dnDataTotal, setFlashPack_TTP_DnDataTotal] = useState({});
    const [flashPack_TTP_dnOptionsTotal, setFlashPack_TTP_DnOptionsTotal] = useState({});
    //-

    //GlobalTO GP
    const [globalTO_GP_dnDataTotal, setGlobalTO_GP_DnDataTotal] = useState({});
    const [globalTO_GP_dnOptionsTotal, setGlobalTO_GP_DnOptionsTotal] = useState({});
    //-

    //GlobalTO TTP
    const [globalTO_TTP_dnDataTotal, setGlobalTO_TTP_DnDataTotal] = useState({});
    const [globalTO_TTP_dnOptionsTotal, setGlobalTO_TTP_DnOptionsTotal] = useState({});
    //-

    //PremAdv GP
    const [premAdv_GP_dnDataTotal, setPremAdv_GP_DnDataTotal] = useState({});
    const [premAdv_GP_dnOptionsTotal, setPremAdv_GP_DnOptionsTotal] = useState({});
    //-

    //PremAdv TTP
    const [premAdv_TTP_dnDataTotal, setPremAdv_TTP_DnDataTotal] = useState({});
    const [premAdv_TTP_dnOptionsTotal, setPremAdv_TTP_DnOptionsTotal] = useState({});
    //-

    //Events GP
    const [events_GP_dnDataTotal, setEvents_GP_DnDataTotal] = useState({});
    const [events_GP_dnOptionsTotal, setEvents_GP_DnOptionsTotal] = useState({});
    //-

    //Events TTP
    const [events_TTP_dnDataTotal, setEvents_TTP_DnDataTotal] = useState({});
    const [events_TTP_dnOptionsTotal, setEvents_TTP_DnOptionsTotal] = useState({});
    //-

    const [CurrentQtr, setCurrentQtr] = useState({});
    const [currentYear, setCurrentYear] = useState("");

    const [isCurrentQtr, setIsCurrentQtr] = useState(true);

    let navigate = useNavigate();

    const colours = {
        "confirmed": "rgba(15,250,0,1)",
        "confirmedTint": "rgba(15,250,0,0.2)",
        "confirmedTintDark": "#04CF35",

        "target": "rgba(215,38,255,1)",
        "targetTint": "rgba(215,38,155,0.4)",

        "mc": "rgba(229,119,24,1)",
        "mcTint": "rgba(229,119,24,0.4)",
        "mcTintDark": "rgba(229,119,24,0.8)",
        "pt": "rgba(44,176,223,1)",
        "ptTint": "rgba(44,176,223,0.4)",
        "ptTintDark": "rgba(44,176,223,0.8)"
    }

    const qtrData = [
        { qtr: "Q1", months: ["January", "February", "March"], confirmedRow: 28, forecastRow: 29 },
        { qtr: "Q2", months: ["April", "May", "June"], confirmedRow: 52, forecastRow: 53 },
        { qtr: "Q3", months: ["July", "August", "September"], confirmedRow: 76, forecastRow: 77 },
        { qtr: "Q4", months: ["October", "November", "December"], confirmedRow: 100, forecastRow: 101 },
    ];

    useEffect(() => {
        if (!sessionStorage.getItem("access_token")) {
            navigate("/");
        } else {

            const d = new Date();

            qtrData.map((item)=>{ 
                if (item.months.includes(d.toLocaleString('en-us', { month: 'long' }))) {
                    setCurrentQtr({
                        "name": item.qtr,
                        "number": qtrData.findIndex((obj) => obj === item)
                    });
                }
            });
            

            setCurrentYear(d.getFullYear());

            api.getSheet(sessionStorage.getItem('access_token'), configuration.sheetName3).then((data) => {
                if (data.name == "AxiosError") {
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    console.log("AXIOS data:", data);
                    setTableData3(data);
                }
            });
        }
    }, []);



    let CurrentQtrData = dataState.filter((el) => {
        return el.qtr == CurrentQtr.name;
    });

    useEffect(() => {
        if (tableData3.length) {

            var qtrDataState = [];

            qtrData.map((data) => {

                //GP
                let targetTotal = dataFormat(tableData3[data.forecastRow][14]);
                let confirmedTotal = dataFormat(tableData3[data.confirmedRow][14]);
                let varianceTotal = targetTotal - confirmedTotal;
                let confirmedPercentageTotal = Math.round(confirmedTotal * 100 / targetTotal);
                //-

                //REV        
                let targetTotal_REV = dataFormat(tableData3[data.forecastRow][15]);
                let confirmedTotal_REV = dataFormat(tableData3[data.confirmedRow][15]);
                let varianceTotal_REV = targetTotal_REV - confirmedTotal_REV;
                let confirmedPercentageTotal_REV = Math.round(confirmedTotal_REV * 100 / targetTotal_REV);
                //-

                //Direct GP
                let directGPTarget = dataFormat(tableData3[data.forecastRow][2]);
                let directGPConfirmed = dataFormat(tableData3[data.confirmedRow][2]);
                let directGPVarianceTotal = directGPTarget - directGPConfirmed;
                let directGPconfirmedPercentageTotal = Math.round(directGPConfirmed * 100 / directGPTarget);
                //-

                //Direct TTP
                let directTTPTarget = dataFormat(tableData3[data.forecastRow][3]);
                let directTTPConfirmed = dataFormat(tableData3[data.confirmedRow][3]);
                let directTTPVarianceTotal = directTTPTarget - directTTPConfirmed;
                let directTTPconfirmedPercentageTotal = Math.round(directTTPConfirmed * 100 / directTTPTarget);
                //-

                //BT GP
                let btGPTarget = dataFormat(tableData3[data.forecastRow][4]);
                let btGPConfirmed = dataFormat(tableData3[data.confirmedRow][4]);
                let btGPVarianceTotal = btGPTarget - btGPConfirmed;
                let btGPconfirmedPercentageTotal = Math.round(btGPConfirmed * 100 / btGPTarget);
                //-

                //BT TTP
                let btTTPTarget = dataFormat(tableData3[data.forecastRow][5]);
                let btTTPConfirmed = dataFormat(tableData3[data.confirmedRow][5]);
                let btTTPVarianceTotal = btTTPTarget - btTTPConfirmed;
                let btTTPconfirmedPercentageTotal = Math.round(btTTPConfirmed * 100 / btTTPTarget);
                //-

                //FlashPack GP
                let flashPackGPTarget = dataFormat(tableData3[data.forecastRow][6]);
                let flashPackGPConfirmed = dataFormat(tableData3[data.confirmedRow][6]);
                let flashPackGPVarianceTotal = flashPackGPTarget - flashPackGPConfirmed;
                let flashPackGPconfirmedPercentageTotal = Math.round(flashPackGPConfirmed * 100 / flashPackGPTarget);
                //-

                //FlashPack TTP
                let flashPackTTPTarget = dataFormat(tableData3[data.forecastRow][7]);
                let flashPackTTPConfirmed = dataFormat(tableData3[data.confirmedRow][7]);
                let flashPackTTPVarianceTotal = flashPackTTPTarget - flashPackTTPConfirmed;
                let flashPackTTPconfirmedPercentageTotal = Math.round(flashPackTTPConfirmed * 100 / flashPackTTPTarget);
                //-

                //GlobalTO GP
                let globalTOGPTarget = dataFormat(tableData3[data.forecastRow][8]);
                let globalTOGPConfirmed = dataFormat(tableData3[data.confirmedRow][8]);
                let globalTOGPVarianceTotal = globalTOGPTarget - globalTOGPConfirmed;
                let globalTOGPconfirmedPercentageTotal = Math.round(globalTOGPConfirmed * 100 / globalTOGPTarget);
                //-

                //GlobalTO TTP
                let globalTOTTPTarget = dataFormat(tableData3[data.forecastRow][9]);
                let globalTOTTPConfirmed = dataFormat(tableData3[data.confirmedRow][9]);
                let globalTOTTPVarianceTotal = globalTOTTPTarget - globalTOTTPConfirmed;
                let globalTOTTPconfirmedPercentageTotal = Math.round(globalTOTTPConfirmed * 100 / globalTOTTPTarget);
                //-

                //PremAdv GP
                let premAdvGPTarget = dataFormat(tableData3[data.forecastRow][10]);
                let premAdvGPConfirmed = dataFormat(tableData3[data.confirmedRow][10]);
                let premAdvGPVarianceTotal = premAdvGPTarget - premAdvGPConfirmed;
                let premAdvGPconfirmedPercentageTotal = Math.round(premAdvGPConfirmed * 100 / premAdvGPTarget);
                //-

                //PremAdv TTP
                let premAdvTTPTarget = dataFormat(tableData3[data.forecastRow][11]);
                let premAdvTTPConfirmed = dataFormat(tableData3[data.confirmedRow][11]);
                let premAdvTTPVarianceTotal = premAdvTTPTarget - premAdvTTPConfirmed;
                let premAdvTTPconfirmedPercentageTotal = Math.round(premAdvTTPConfirmed * 100 / premAdvTTPTarget);
                //-

                //Events GP
                let eventsGPTarget = dataFormat(tableData3[data.forecastRow][12]);
                let eventsGPConfirmed = dataFormat(tableData3[data.confirmedRow][12]);
                let eventsGPVarianceTotal = eventsGPTarget - eventsGPConfirmed;
                let eventsGPconfirmedPercentageTotal = Math.round(eventsGPConfirmed * 100 / eventsGPTarget);
                //-

                //Events TTP
                let eventsTTPTarget = dataFormat(tableData3[data.forecastRow][13]);
                let eventsTTPConfirmed = dataFormat(tableData3[data.confirmedRow][13]);
                let eventsTTPVarianceTotal = eventsTTPTarget - eventsTTPConfirmed;
                let eventsTTPconfirmedPercentageTotal = Math.round(eventsTTPConfirmed * 100 / eventsTTPTarget);
                //-




                //qtrDataState.push({
                qtrDataState.push({
                    qtr: data.qtr,

                    //GP
                    targetTotal: targetTotal,
                    confirmedTotal: confirmedTotal,
                    varianceTotal: varianceTotal,
                    confirmedPercentageTotal: confirmedPercentageTotal,
                    //-

                    //REV
                    targetTotal_REV: targetTotal_REV,
                    confirmedTotal_REV: confirmedTotal_REV,
                    varianceTotal_REV: varianceTotal_REV,
                    confirmedPercentageTotal_REV: confirmedPercentageTotal_REV,
                    //-

                    //Direct GP
                    directGPTarget: directGPTarget,
                    directGPConfirmed: directGPConfirmed,
                    directGPVarianceTotal: directGPVarianceTotal,
                    directGPconfirmedPercentageTotal: directGPconfirmedPercentageTotal,
                    //-

                    //Direct TTP
                    directTTPTarget: directTTPTarget,
                    directTTPConfirmed: directTTPConfirmed,
                    directTTPVarianceTotal: directTTPVarianceTotal,
                    directTTPconfirmedPercentageTotal: directTTPconfirmedPercentageTotal,
                    //-

                    //BT GP
                    btGPTarget: btGPTarget,
                    btGPConfirmed: btGPConfirmed,
                    btGPVarianceTotal: btGPVarianceTotal,
                    btGPconfirmedPercentageTotal: btGPconfirmedPercentageTotal,
                    //-

                    //BT TTP
                    btTTPTarget: btTTPTarget,
                    btTTPConfirmed: btTTPConfirmed,
                    btTTPVarianceTotal: btTTPVarianceTotal,
                    btTTPconfirmedPercentageTotal: btTTPconfirmedPercentageTotal,
                    //-

                    //FlashPack GP
                    flashPackGPTarget: flashPackGPTarget,
                    flashPackGPConfirmed: flashPackGPConfirmed,
                    flashPackGPVarianceTotal: flashPackGPVarianceTotal,
                    flashPackGPconfirmedPercentageTotal: flashPackGPconfirmedPercentageTotal,
                    //-

                    //FlashPack TTP
                    flashPackTTPTarget: flashPackTTPTarget,
                    flashPackTTPConfirmed: flashPackTTPConfirmed,
                    flashPackTTPVarianceTotal: flashPackTTPVarianceTotal,
                    flashPackTTPconfirmedPercentageTotal: flashPackTTPconfirmedPercentageTotal,
                    //-

                    //GlobalTO GP
                    globalTOGPTarget: globalTOGPTarget,
                    globalTOGPConfirmed: globalTOGPConfirmed,
                    globalTOGPVarianceTotal: globalTOGPVarianceTotal,
                    globalTOGPconfirmedPercentageTotal: globalTOGPconfirmedPercentageTotal,
                    //-

                    //GlobalTO TTP
                    globalTOTTPTarget: globalTOTTPTarget,
                    globalTOTTPConfirmed: globalTOTTPConfirmed,
                    globalTOTTPVarianceTotal: globalTOTTPVarianceTotal,
                    globalTOTTPconfirmedPercentageTotal: globalTOTTPconfirmedPercentageTotal,
                    //-

                    //PremAdv GP
                    premAdvGPTarget: premAdvGPTarget,
                    premAdvGPConfirmed: premAdvGPConfirmed,
                    premAdvGPVarianceTotal: premAdvGPVarianceTotal,
                    premAdvGPconfirmedPercentageTotal: premAdvGPconfirmedPercentageTotal,
                    //-

                    //PremAdv TTP
                    premAdvTTPTarget: premAdvTTPTarget,
                    premAdvTTPConfirmed: premAdvTTPConfirmed,
                    premAdvTTPVarianceTotal: premAdvTTPVarianceTotal,
                    premAdvTTPconfirmedPercentageTotal: premAdvTTPconfirmedPercentageTotal,
                    //-

                    //Events GP
                    eventsGPTarget: eventsGPTarget,
                    eventsGPConfirmed: eventsGPConfirmed,
                    eventsGPVarianceTotal: eventsGPVarianceTotal,
                    eventsGPconfirmedPercentageTotal: eventsGPconfirmedPercentageTotal,
                    //-

                    //Events TTP
                    eventsTTPTarget: eventsTTPTarget,
                    eventsTTPConfirmed: eventsTTPConfirmed,
                    eventsTTPVarianceTotal: eventsTTPVarianceTotal,
                    eventsTTPconfirmedPercentageTotal: eventsTTPconfirmedPercentageTotal,
                    //-



                })
            });
            //setDataState(qtrDataState);
            setDataState(qtrDataState);
        }
    }, [tableData3, CurrentQtr])

    useEffect(() => {

        console.log("CurrentQtrData: ", CurrentQtrData[0]);

        const sumConfirmedTotal = dataState.slice(0, CurrentQtr.number + 1).map((item) => {
            return item.confirmedTotal;
        });
        const sumTargetTotal = dataState.slice(0, CurrentQtr.number + 1).map((item) => {
            return item.targetTotal;
        });
        const confirmedTotalStart = dataState[0]?.confirmedTotal;
        const confirmedTotalEnd = sumConfirmedTotal.reduce((partialSum, a) => partialSum + a, 0);
        const targetTotalStart = dataState[0]?.targetTotal;
        const targetTotalEnd = sumTargetTotal.reduce((partialSum, a) => partialSum + a, 0);

        const sumConfirmedTotal_REV = dataState.slice(0, CurrentQtr.number + 1).map((item) => {
            return item.confirmedTotal_REV;
        });
        const sumTargetTotal_REV = dataState.slice(0, CurrentQtr.number + 1).map((item) => {
            return item.targetTotal_REV;
        });
        const confirmedTotalStart_REV = dataState[0]?.confirmedTotal_REV;
        const confirmedTotalEnd_REV = sumConfirmedTotal_REV.reduce((partialSum, a) => partialSum + a, 0);
        const targetTotalStart_REV = dataState[0]?.targetTotal_REV;
        const targetTotalEnd_REV = sumTargetTotal_REV.reduce((partialSum, a) => partialSum + a, 0);

        //Total GP LineChart
        setYearLineChartData({
            labels: dataState.slice(0, CurrentQtr.number + 1).map((item) => {
                var shortMonthName = item.qtr;
                return shortMonthName;
            }),
            datasets: [
                {
                    label: "Target",
                    data: dataState.slice(0, CurrentQtr.number + 1).map((item) => item.targetTotal),
                    fill: true,
                    borderColor: colours.target,
                    backgroundColor: colours.targetTint
                },
                {
                    label: "Confirmed",
                    data: dataState.slice(0, CurrentQtr.number + 1).map((item) => item.confirmedTotal),
                    fill: true,
                    borderColor: colours.confirmed,
                    backgroundColor: colours.confirmedTint
                }
            ]
        });
        //-

        //Total Revenue LineChart
        setYearLineChartData_REV({
            //labels: [dataState[0]?.qtr, dataState[CurrentQtr.number]?.qtr],
            labels: dataState.slice(0, CurrentQtr.number + 1).map((item) => {
                var shortMonthName = item.qtr;
                return shortMonthName;
            }),
            datasets: [
                {
                    label: "Target",
                    data: dataState.slice(0, CurrentQtr.number + 1).map((item) => item.targetTotal_REV),
                    fill: true,
                    borderColor: colours.target,
                    backgroundColor: colours.targetTint
                },
                {
                    label: "Confirmed",
                    data: dataState.slice(0, CurrentQtr.number + 1).map((item) => item.confirmedTotal_REV),
                    fill: true,
                    borderColor: colours.confirmed,
                    backgroundColor: colours.confirmedTint
                }
            ]
        });
        //-

        //Sum Total GP LineChart
        setSumYearLineChartData({
            labels: [dataState[0]?.qtr, dataState[CurrentQtr.number]?.qtr],
            datasets: [
                {
                    label: "Target",
                    data: [targetTotalStart, targetTotalEnd],
                    fill: true,
                    borderColor: colours.target,
                    backgroundColor: colours.targetTint
                },
                {
                    label: "Confirmed",
                    data: [confirmedTotalStart, confirmedTotalEnd],
                    fill: true,
                    borderColor: colours.confirmed,
                    backgroundColor: colours.confirmedTint
                }
            ]
        });
        //-

        //Sum Total Revenue LineChart
        setSumYearLineChartData_REV({
            labels: [dataState[0]?.qtr, dataState[CurrentQtr.number]?.qtr],
            datasets: [
                {
                    label: "Target",
                    data: [targetTotalStart_REV, targetTotalEnd_REV],
                    fill: true,
                    borderColor: colours.target,
                    backgroundColor: colours.targetTint
                },
                {
                    label: "Confirmed",
                    data: [confirmedTotalStart_REV, confirmedTotalEnd_REV],
                    fill: true,
                    borderColor: colours.confirmed,
                    backgroundColor: colours.confirmedTint
                }
            ]
        });
        //-





        //Totals GP Doughnut
        var DnLabels = ["Variance", "Confirmed"];
        var DnData = [CurrentQtrData[0]?.varianceTotal, CurrentQtrData[0]?.confirmedTotal];
        var DnBorderColors = ['#0FFA00'];
        var DnFillColors = ['#0FFA00', '#313640'];
        if (CurrentQtrData[0]?.varianceTotal < 0) {
            DnFillColors = ['#04CF35', '#0FFA00'];
        }

        setDnDataTotal({
            labels: CurrentQtrData[0]?.varianceTotal < 0 ? DnLabels : DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.varianceTotal < 0 ? DnData : DnData.reverse(),
                fill: true,
                borderColor: DnBorderColors,
                backgroundColor: DnFillColors,
                weight: 20
            }]
        });

        setDnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.confirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.confirmedPercentagePT).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-


        //Totals REV Doughnut
        var DnLabels_REV = ["Variance", "Confirmed"];
        var DnData_REV = [CurrentQtrData[0]?.varianceTotal_REV, CurrentQtrData[0]?.confirmedTotal_REV];
        var DnBorderColors_REV = ['#0FFA00'];
        var DnFillColors_REV = ['#0FFA00', '#313640'];
        if (CurrentQtrData[0]?.varianceTotal_REV < 0) {
            DnFillColors_REV = ['#04CF35', '#0FFA00'];
        }

        setDnDataTotal_REV({
            labels: CurrentQtrData[0]?.varianceTotal_REV < 0 ? DnLabels_REV : DnLabels_REV.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.varianceTotal_REV < 0 ? DnData_REV : DnData_REV.reverse(),
                fill: true,
                borderColor: DnBorderColors_REV,
                backgroundColor: DnFillColors_REV,
                weight: 20
            }]
        });

        setDnOptionsTotal_REV({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.confirmedPercentageTotal_REV + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.confirmedPercentagePT_REV).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-


        //Direct GP Doughnut
        var direct_GP_DnLabels = ["Variance", "Confirmed"];
        var direct_GP_DnData = [CurrentQtrData[0]?.directGPVarianceTotal, CurrentQtrData[0]?.directGPConfirmed];
        var direct_GP_DnBorderColors = colours.target;
        var direct_GP_DnFillColors = [colours.target, colours.targetTint];
        if (CurrentQtrData[0]?.directGPVarianceTotal < 0) {
            direct_GP_DnFillColors = [colours.targetTint, colours.target];
        }

        setDirect_GP_DnDataTotal({
            labels: CurrentQtrData[0]?.directGPVarianceTotal < 0 ? direct_GP_DnLabels : direct_GP_DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.directGPVarianceTotal < 0 ? direct_GP_DnData : direct_GP_DnData.reverse(),
                fill: true,
                borderColor: direct_GP_DnBorderColors,
                backgroundColor: direct_GP_DnFillColors,
                weight: 20
            }]
        });

        setDirect_GP_DnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.directGPconfirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.directGPconfirmedPercentageTotal).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-

        //Direct TTP Doughnut
        var direct_TTP_DnLabels = ["Variance", "Confirmed"];
        var direct_TTP_DnData = [CurrentQtrData[0]?.directTTPVarianceTotal, CurrentQtrData[0]?.directTTPConfirmed];
        var direct_TTP_DnBorderColors = colours.target;
        var direct_TTP_DnFillColors = [colours.target, colours.targetTint];
        if (CurrentQtrData[0]?.directTTPVarianceTotal < 0) {
            direct_TTP_DnFillColors = [colours.targetTint, colours.target];
        }

        setDirect_TTP_DnDataTotal({
            labels: CurrentQtrData[0]?.directTTPVarianceTotal < 0 ? direct_TTP_DnLabels : direct_TTP_DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.directTTPVarianceTotal < 0 ? direct_TTP_DnData : direct_TTP_DnData.reverse(),
                fill: true,
                borderColor: direct_TTP_DnBorderColors,
                backgroundColor: direct_TTP_DnFillColors,
                weight: 20
            }]
        });

        setDirect_TTP_DnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.directTTPconfirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.directTTPconfirmedPercentageTotal).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-

        //BT GP Doughnut
        var bt_GP_DnLabels = ["Variance", "Confirmed"];
        var bt_GP_DnData = [CurrentQtrData[0]?.btGPVarianceTotal, CurrentQtrData[0]?.btGPConfirmed];
        var bt_GP_DnBorderColors = colours.target;
        var bt_GP_DnFillColors = [colours.target, colours.targetTint];
        if (CurrentQtrData[0]?.btGPVarianceTotal < 0) {
            bt_GP_DnFillColors = [colours.targetTint, colours.target];
        }

        setBT_GP_DnDataTotal({
            labels: CurrentQtrData[0]?.btGPVarianceTotal < 0 ? bt_GP_DnLabels : bt_GP_DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.btGPVarianceTotal < 0 ? bt_GP_DnData : bt_GP_DnData.reverse(),
                fill: true,
                borderColor: bt_GP_DnBorderColors,
                backgroundColor: bt_GP_DnFillColors,
                weight: 20
            }]
        });

        setBT_GP_DnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.btGPconfirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.btGPconfirmedPercentageTotal).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-

        //BT TTP Doughnut
        var bt_TTP_DnLabels = ["Variance", "Confirmed"];
        var bt_TTP_DnData = [CurrentQtrData[0]?.btTTPVarianceTotal, CurrentQtrData[0]?.btTTPConfirmed];
        var bt_TTP_DnBorderColors = colours.target;
        var bt_TTP_DnFillColors = [colours.target, colours.targetTint];
        if (CurrentQtrData[0]?.btTTPVarianceTotal < 0) {
            bt_TTP_DnFillColors = [colours.targetTint, colours.target];
        }

        setBT_TTP_DnDataTotal({
            labels: CurrentQtrData[0]?.btTTPVarianceTotal < 0 ? bt_TTP_DnLabels : bt_TTP_DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.btTTPVarianceTotal < 0 ? bt_TTP_DnData : bt_TTP_DnData.reverse(),
                fill: true,
                borderColor: bt_TTP_DnBorderColors,
                backgroundColor: bt_TTP_DnFillColors,
                weight: 20
            }]
        });

        setBT_TTP_DnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.btTTPconfirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.btTTPconfirmedPercentageTotal).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-

        //FlashPack GP Doughnut
        var flashPack_GP_DnLabels = ["Variance", "Confirmed"];
        var flashPack_GP_DnData = [CurrentQtrData[0]?.flashPackGPVarianceTotal, CurrentQtrData[0]?.flashPackGPConfirmed];
        var flashPack_GP_DnBorderColors = colours.target;
        var flashPack_GP_DnFillColors = [colours.target, colours.targetTint];
        if (CurrentQtrData[0]?.flashPackGPVarianceTotal < 0) {
            flashPack_GP_DnFillColors = [colours.targetTint, colours.target];
        }

        setFlashPack_GP_DnDataTotal({
            labels: CurrentQtrData[0]?.flashPackGPVarianceTotal < 0 ? flashPack_GP_DnLabels : flashPack_GP_DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.flashPackGPVarianceTotal < 0 ? flashPack_GP_DnData : flashPack_GP_DnData.reverse(),
                fill: true,
                borderColor: flashPack_GP_DnBorderColors,
                backgroundColor: flashPack_GP_DnFillColors,
                weight: 20
            }]
        });

        setFlashPack_GP_DnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.flashPackGPconfirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.flashPackGPconfirmedPercentageTotal).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-

        //FlashPack TTP Doughnut
        var flashPack_TTP_DnLabels = ["Variance", "Confirmed"];
        var flashPack_TTP_DnData = [CurrentQtrData[0]?.flashPackTTPVarianceTotal, CurrentQtrData[0]?.flashPackTTPConfirmed];
        var flashPack_TTP_DnBorderColors = colours.target;
        var flashPack_TTP_DnFillColors = [colours.target, colours.targetTint];
        if (CurrentQtrData[0]?.flashPackTTPVarianceTotal < 0) {
            flashPack_TTP_DnFillColors = [colours.targetTint, colours.target];
        }

        setFlashPack_TTP_DnDataTotal({
            labels: CurrentQtrData[0]?.flashPackTTPVarianceTotal < 0 ? flashPack_TTP_DnLabels : flashPack_TTP_DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.flashPackTTPVarianceTotal < 0 ? flashPack_TTP_DnData : flashPack_TTP_DnData.reverse(),
                fill: true,
                borderColor: flashPack_TTP_DnBorderColors,
                backgroundColor: flashPack_TTP_DnFillColors,
                weight: 20
            }]
        });

        setFlashPack_TTP_DnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.flashPackTTPconfirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.flashPackTTPconfirmedPercentageTotal).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-

        //GlobalTO GP Doughnut
        var globalTO_GP_DnLabels = ["Variance", "Confirmed"];
        var globalTO_GP_DnData = [CurrentQtrData[0]?.globalTOGPVarianceTotal, CurrentQtrData[0]?.globalTOGPConfirmed];
        var globalTO_GP_DnBorderColors = colours.target;
        var globalTO_GP_DnFillColors = [colours.target, colours.targetTint];
        if (CurrentQtrData[0]?.globalTOGPVarianceTotal < 0) {
            globalTO_GP_DnFillColors = [colours.targetTint, colours.target];
        }

        setGlobalTO_GP_DnDataTotal({
            labels: CurrentQtrData[0]?.globalTOGPVarianceTotal < 0 ? globalTO_GP_DnLabels : globalTO_GP_DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.globalTOGPVarianceTotal < 0 ? globalTO_GP_DnData : globalTO_GP_DnData.reverse(),
                fill: true,
                borderColor: globalTO_GP_DnBorderColors,
                backgroundColor: globalTO_GP_DnFillColors,
                weight: 20
            }]
        });

        setGlobalTO_GP_DnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.globalTOGPconfirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.globalTOGPconfirmedPercentageTotal).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-

        //GlobalTO TTP Doughnut
        var globalTO_TTP_DnLabels = ["Variance", "Confirmed"];
        var globalTO_TTP_DnData = [CurrentQtrData[0]?.globalTOTTPVarianceTotal, CurrentQtrData[0]?.globalTOTTPConfirmed];
        var globalTO_TTP_DnBorderColors = colours.target;
        var globalTO_TTP_DnFillColors = [colours.target, colours.targetTint];
        if (CurrentQtrData[0]?.globalTOTTPVarianceTotal < 0) {
            globalTO_TTP_DnFillColors = [colours.targetTint, colours.target];
        }

        setGlobalTO_TTP_DnDataTotal({
            labels: CurrentQtrData[0]?.globalTOTTPVarianceTotal < 0 ? globalTO_TTP_DnLabels : globalTO_TTP_DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.globalTOTTPVarianceTotal < 0 ? globalTO_TTP_DnData : globalTO_TTP_DnData.reverse(),
                fill: true,
                borderColor: globalTO_TTP_DnBorderColors,
                backgroundColor: globalTO_TTP_DnFillColors,
                weight: 20
            }]
        });

        setGlobalTO_TTP_DnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.globalTOTTPconfirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.globalTOTTPconfirmedPercentageTotal).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-

        //PremAdv GP Doughnut
        var premAdv_GP_DnLabels = ["Variance", "Confirmed"];
        var premAdv_GP_DnData = [CurrentQtrData[0]?.premAdvGPVarianceTotal, CurrentQtrData[0]?.premAdvGPConfirmed];
        var premAdv_GP_DnBorderColors = colours.target;
        var premAdv_GP_DnFillColors = [colours.target, colours.targetTint];
        if (CurrentQtrData[0]?.premAdvGPVarianceTotal < 0) {
            premAdv_GP_DnFillColors = [colours.targetTint, colours.target];
        }

        setPremAdv_GP_DnDataTotal({
            labels: CurrentQtrData[0]?.premAdvGPVarianceTotal < 0 ? premAdv_GP_DnLabels : premAdv_GP_DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.premAdvGPVarianceTotal < 0 ? premAdv_GP_DnData : premAdv_GP_DnData.reverse(),
                fill: true,
                borderColor: premAdv_GP_DnBorderColors,
                backgroundColor: premAdv_GP_DnFillColors,
                weight: 20
            }]
        });

        setPremAdv_GP_DnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.premAdvGPconfirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.premAdvGPconfirmedPercentageTotal).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-

        //PremAdv TTP Doughnut
        var premAdv_TTP_DnLabels = ["Variance", "Confirmed"];
        var premAdv_TTP_DnData = [CurrentQtrData[0]?.premAdvTTPVarianceTotal, CurrentQtrData[0]?.premAdvTTPConfirmed];
        var premAdv_TTP_DnBorderColors = colours.target;
        var premAdv_TTP_DnFillColors = [colours.target, colours.targetTint];
        if (CurrentQtrData[0]?.premAdvTTPVarianceTotal < 0) {
            premAdv_TTP_DnFillColors = [colours.targetTint, colours.target];
        }

        setPremAdv_TTP_DnDataTotal({
            labels: CurrentQtrData[0]?.premAdvTTPVarianceTotal < 0 ? premAdv_TTP_DnLabels : premAdv_TTP_DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.premAdvTTPVarianceTotal < 0 ? premAdv_TTP_DnData : premAdv_TTP_DnData.reverse(),
                fill: true,
                borderColor: premAdv_TTP_DnBorderColors,
                backgroundColor: premAdv_TTP_DnFillColors,
                weight: 20
            }]
        });

        setPremAdv_TTP_DnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.premAdvTTPconfirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.premAdvTTPconfirmedPercentageTotal).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-

        //Events GP Doughnut
        var events_GP_DnLabels = ["Variance", "Confirmed"];
        var events_GP_DnData = [CurrentQtrData[0]?.eventsGPVarianceTotal, CurrentQtrData[0]?.eventsGPConfirmed];
        var events_GP_DnBorderColors = colours.target;
        var events_GP_DnFillColors = [colours.target, colours.targetTint];
        if (CurrentQtrData[0]?.eventsGPVarianceTotal < 0) {
            events_GP_DnFillColors = [colours.targetTint, colours.target];
        }

        setEvents_GP_DnDataTotal({
            labels: CurrentQtrData[0]?.eventsGPVarianceTotal < 0 ? events_GP_DnLabels : events_GP_DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.eventsGPVarianceTotal < 0 ? events_GP_DnData : events_GP_DnData.reverse(),
                fill: true,
                borderColor: events_GP_DnBorderColors,
                backgroundColor: events_GP_DnFillColors,
                weight: 20
            }]
        });

        setEvents_GP_DnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.eventsGPconfirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.eventsGPconfirmedPercentageTotal).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-

        //Events TTP Doughnut
        var events_TTP_DnLabels = ["Variance", "Confirmed"];
        var events_TTP_DnData = [CurrentQtrData[0]?.eventsTTPVarianceTotal, CurrentQtrData[0]?.eventsTTPConfirmed];
        var events_TTP_DnBorderColors = colours.target;
        var events_TTP_DnFillColors = [colours.target, colours.targetTint];
        if (CurrentQtrData[0]?.eventsTTPVarianceTotal < 0) {
            events_TTP_DnFillColors = [colours.targetTint, colours.target];
        }

        setEvents_TTP_DnDataTotal({
            labels: CurrentQtrData[0]?.eventsTTPVarianceTotal < 0 ? events_TTP_DnLabels : events_TTP_DnLabels.reverse(),
            datasets: [{
                label: "target",
                data: CurrentQtrData[0]?.eventsTTPVarianceTotal < 0 ? events_TTP_DnData : events_TTP_DnData.reverse(),
                fill: true,
                borderColor: events_TTP_DnBorderColors,
                backgroundColor: events_TTP_DnFillColors,
                weight: 20
            }]
        });

        setEvents_TTP_DnOptionsTotal({
            cutout: "80%",
            plugins: {
                legend: {
                    display: false
                },
                doughnutlabel: {
                    color: '#fff',
                    labels: [{
                        text: CurrentQtrData[0]?.eventsTTPconfirmedPercentageTotal + '%',
                        font: {
                            size: toString(CurrentQtrData[0]?.eventsTTPconfirmedPercentageTotal).length * 2,
                            weight: 'bold'
                        }
                    }, {
                        text: 'of target',
                        font: {
                            size: 15
                        }
                    }]
                }
            }
        });
        //-


    }, [dataState]);

    const lineOptions = {
        radius: 5,
        color: "white",
        legend: {
            labels: {
                padding: 40
            }
        },
        scales: {
            x: {
                ticks: {
                    color: "white"
                },
                grid: {
                    color: "#313640"
                }
            },
            y: {
                ticks: {
                    color: "white"
                },
                grid: {
                    color: "#313640"
                }
            }
        }
    };

    function handleRefresh() {
        window.location.reload();
        return true;
    }

    return (
        <>
            <PullToRefresh onRefresh={handleRefresh} className="pullRefresh">
                <div style={{ height: "100%" }}>
                    {!tableData3.length && <Loader />}
                    <div className='Section'>
                        {/* <h3 className='SectionTitle'>Sales By GP</h3> */}
                        <div className='CardsContainer'>
                            <div className='CardWrapper CardWrapperMain Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header'><strong>Sales by GP Total:</strong><span>{CurrentQtrData[0]?.qtr}</span></div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={dnDataTotal} options={dnOptionsTotal} />}
                                        </div>
                                        <div className='Labels'>
                                            <p><b>Target:</b> {numberWithCommas(CurrentQtrData[0]?.targetTotal)}</p>
                                            <p><b>Confirmed:</b> {numberWithCommas(CurrentQtrData[0]?.confirmedTotal)}</p>
                                            <p><b>Variance:</b> {numberWithCommas(CurrentQtrData[0]?.varianceTotal)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CardWrapper Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header' style={{ justifyContent: "center" }}><strong>Direct</strong>
                                    </div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={direct_GP_dnDataTotal} options={direct_GP_dnOptionsTotal} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CardWrapper Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header' style={{ justifyContent: "center" }}><strong>BT</strong>
                                    </div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={bt_GP_dnDataTotal} options={bt_GP_dnOptionsTotal} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CardWrapper Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header' style={{ justifyContent: "center" }}><strong>Flash Pack</strong>
                                    </div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={flashPack_GP_dnDataTotal} options={flashPack_GP_dnOptionsTotal} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CardWrapper Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header' style={{ justifyContent: "center" }}><strong>Global T.O.</strong>
                                    </div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={globalTO_GP_dnDataTotal} options={globalTO_GP_dnOptionsTotal} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CardWrapper Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header' style={{ justifyContent: "center" }}><strong>Premium Adv.</strong>
                                    </div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={premAdv_GP_dnDataTotal} options={premAdv_GP_dnOptionsTotal} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CardWrapper Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header' style={{ justifyContent: "center" }}><strong>Events</strong>
                                    </div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={events_GP_dnDataTotal} options={events_GP_dnOptionsTotal} />}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='Section'>
                        {/* <h3 className='SectionTitle'>Sales By Revenue</h3> */}
                        <div className='CardsContainer'>
                            <div className='CardWrapper CardWrapperMain Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header'><strong>Sales by Revenue Total:</strong><span>{CurrentQtrData[0]?.qtr}</span></div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={dnDataTotal_REV} options={dnOptionsTotal_REV} />}
                                        </div>
                                        <div className='Labels'>
                                            <p><b>Target:</b> {numberWithCommas(CurrentQtrData[0]?.targetTotal_REV)}</p>
                                            <p><b>Confirmed:</b> {numberWithCommas(CurrentQtrData[0]?.confirmedTotal_REV)}</p>
                                            <p><b>Variance:</b> {numberWithCommas(CurrentQtrData[0]?.varianceTotal_REV)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CardWrapper Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header' style={{ justifyContent: "center" }}><strong>Direct</strong>
                                    </div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={direct_TTP_dnDataTotal} options={direct_TTP_dnOptionsTotal} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CardWrapper Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header' style={{ justifyContent: "center" }}><strong>BT</strong>
                                    </div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={bt_TTP_dnDataTotal} options={bt_TTP_dnOptionsTotal} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CardWrapper Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header' style={{ justifyContent: "center" }}><strong>Flash Pack</strong>
                                    </div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={flashPack_TTP_dnDataTotal} options={flashPack_TTP_dnOptionsTotal} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CardWrapper Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header' style={{ justifyContent: "center" }}><strong>Global T.O.</strong>
                                    </div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={globalTO_TTP_dnDataTotal} options={globalTO_TTP_dnOptionsTotal} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CardWrapper Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header' style={{ justifyContent: "center" }}><strong>Premium Adv.</strong>
                                    </div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={premAdv_TTP_dnDataTotal} options={premAdv_TTP_dnOptionsTotal} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CardWrapper Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header' style={{ justifyContent: "center" }}><strong>Events</strong>
                                    </div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={events_TTP_dnDataTotal} options={events_TTP_dnOptionsTotal} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Section'>
                        <div className='CardWrapper CardWrapperMain LineChart'>
                            <div className='Card'>
                                <div className='Card-header'><strong>Total Sales By GP</strong><span>{currentYear}</span></div>
                                <div className='Card-content'>
                                    {dataState.length && <LineChart chartData={yearLineChartData} options={lineOptions} />}
                                </div>
                            </div>
                        </div>
                        <div className='CardWrapper CardWrapperMain LineChart'>
                            <div className='Card'>
                                <div className='Card-header'><strong>Sum Total Sales By GP</strong><span>{currentYear}</span></div>
                                <div className='Card-content'>
                                    {dataState.length && <LineChart chartData={sumYearLineChartData} options={lineOptions} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Section'>
                        <div className='CardWrapper CardWrapperMain LineChart'>
                            <div className='Card'>
                                <div className='Card-header'><strong>Total Sales By Revenue</strong><span>{currentYear}</span></div>
                                <div className='Card-content'>
                                    {dataState.length && <LineChart chartData={yearLineChartData_REV} options={lineOptions} />}
                                </div>
                            </div>
                        </div>
                        <div className='CardWrapper CardWrapperMain LineChart'>
                            <div className='Card'>
                                <div className='Card-header'><strong>Sum Total Sales By Revenue</strong><span>{currentYear}</span></div>
                                <div className='Card-content'>
                                    {dataState.length && <LineChart chartData={sumYearLineChartData_REV} options={lineOptions} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='BottomNav'>
                        <div className='ButtonGroup'>
                            <button className={!isCurrentQtr ? 'active' : ''} onClick={() => {
                                console.log("CQ",CurrentQtr.number);
                                if (CurrentQtr.number != 0) {
                                    setCurrentQtr({
                                        "name": qtrData[CurrentQtr.number - 1].qtr,
                                        "number": CurrentQtr.number - 1
                                    });
                                    setIsCurrentQtr(false);
                                }
                            }}><span>Previous</span></button>
                            <button className={isCurrentQtr ? 'active' : ''} onClick={() => {
                                const d = new Date();
                                qtrData.map((item)=>{ 
                                    if (item.months.includes(d.toLocaleString('en-us', { month: 'long' }))) {
                                        console.log("QTR ITEM",item);
                                        setCurrentQtr({
                                            "name": item.qtr,
                                            "number": qtrData.findIndex((obj) => obj === item)
                                        });
                                    }
                                });
                                setIsCurrentQtr(true);
                            }}><span>Current</span></button>
                            {/* <button className={!isCurrentQtr ? 'active' : ''} onClick={() => {
                                if (CurrentQtr.number != 3) {
                                    setCurrentQtr({
                                        "name": qtrData[CurrentQtr.number + 1].qtr,
                                        "number": CurrentQtr.number + 1
                                    });
                                    setIsCurrentQtr(false);
                                }
                            }}><span>Next</span></button> */}
                        </div>
                    </div>
                </div>
            </PullToRefresh>
        </>
    )
}

export default QuarterlyTargets