import axios from "axios";
import * as configuration from "./config";

export function getSheet(access_token, sheet_name){
    var url;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      url = `https://sheets.googleapis.com/v4/spreadsheets/${configuration.developmentGoogleSheetId}/values/${sheet_name}!A1:T`;
    } else {
      url = `https://sheets.googleapis.com/v4/spreadsheets/${configuration.productionGoogleSheetId}/values/${sheet_name}!A1:T`;
    }

    var config = {
        method: 'get',
        url: url,
        headers: { 
          'Authorization': `Bearer ${access_token}`
        }
      };
      
      return axios(config)
      .then(function (response) {
        console.log(response.data);
        return response.data.values;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
}

export function getAccessToken(code){

  var url;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    url = `${configuration.developmentApiUrl}index.php?code=${code}`;
  } else {
    url = `${configuration.productionApiUrl}index.php?code=${code}`;
  }

    var config = {
        method: 'get',
        url: url
      };
      
     return axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        return response.data.access_token;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
}