export const developmentClientId = "741521752465-ud84s36re4rd8o8st3mon7okd70ngvqg.apps.googleusercontent.com";
export const productionClientId = "964083639204-9pl0q6kd94n38s5rkcdjids3pijskilo.apps.googleusercontent.com";

export const developmentApiUrl = "http://localhost/gsheets-api/";
export const productionApiUrl = "https://dash.appserve.space/gsheets-api/";

//export const developmentGoogleSheetId = "1wW8DOJTfODeEiuq6gwibOJAWyoK5dlx2GNlTTbGtLpY"; // 2022
//export const developmentGoogleSheetId = "13t_0Lh8LSQ04uBF6oqB-xBmeAtvsp2OCn8__kyfAp_M"; // 2023
export const developmentGoogleSheetId = "1MJ7UHdG1ZD66M6ioHuT2Df137oh79QsXbZtzbJKPWm0"; // 2024
//export const productionGoogleSheetId = "16qk8LQ3XmyyGbcxwrLMi4WKSHyPC3Dcqc9pHh78wwHA"; // 2022
//export const productionGoogleSheetId = "1Bmbr80f4l4cFemqRzHuUNf_bqZidp8_UbuBhpIN0Aco"; // 2023
export const productionGoogleSheetId = "1VwERjka0gTWZcyZxlFgGEygcZFufYdZnZ3dQtKiazO4"; // 2024

//export const sheetName1 = "SALES BY GP 2022"; // 2022
//export const sheetName1 = "GP 2023"; // 2023
//export const sheetName1 = "GP 2024"; // 2024
//export const sheetName2 = "SALES BY REVENUE 2022"; // 2022
//export const sheetName2 = "SALES 2023"; // 2023
//export const sheetName2 = "SALES 2024"; // 2024
export const sheetName3 = "Epic Dash 2024"; // 2024
export const sheetName4 = "REVENUE 2024 DETAILS"; // 2024
