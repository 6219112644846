import '../App.css';
import { useEffect, useState } from 'react';
import LineChart from '../components/LineChart';
import DoughnutChart from '../components/DoughnutChart';
import { useGlobalState } from '../globalState';
import { useNavigate, Link } from 'react-router-dom';
import * as api from "../api";
import Loader from '../components/loader';
import PullToRefresh from 'react-simple-pull-to-refresh';
import * as configuration from '../config';

function dataFormat(str) {
    //console.log(str);
    //return parseFloat(str.replace(/,/g, ''));
    return parseFloat(str.replace(/[\s,]/g, '')).toFixed(2);
}

function numberWithCommas(x) {
    if (x != undefined) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

function removeSpaceToLower(str){
    if(str != undefined){
        return str.replace(/\s/g,'').toLowerCase();
    }
}

function QuarterlyTargetsTravelDate() {

    const [tableData4, setTableData4] = useGlobalState('tableData4');
    const [dataState, setDataState] = useState([]);


    const [yearLineChartData_REV, setYearLineChartData_REV] = useState({});

    const [sumYearLineChartData_REV, setSumYearLineChartData_REV] = useState({});


    const [dnDataTotal_REV, setDnDataTotal_REV] = useState({});
    const [dnOptionsTotal_REV, setDnOptionsTotal_REV] = useState({});


    const [CurrentQtr, setCurrentQtr] = useState();
    const [currentYear, setCurrentYear] = useState("");

    const [qtrData4, setQtrData4] = useState([]);
    const [CurrentQtrData, setCurrentQtrData] = useState([]);

    let navigate = useNavigate();

    const colours = {
        "confirmed": "rgba(15,250,0,1)",
        "confirmedTint": "rgba(15,250,0,0.2)",
        "confirmedTintDark": "#04CF35",

        "target": "rgba(215,38,255,1)",
        "targetTint": "rgba(215,38,155,0.4)",

        "mc": "rgba(229,119,24,1)",
        "mcTint": "rgba(229,119,24,0.4)",
        "mcTintDark": "rgba(229,119,24,0.8)",
        "pt": "rgba(44,176,223,1)",
        "ptTint": "rgba(44,176,223,0.4)",
        "ptTintDark": "rgba(44,176,223,0.8)"
    }


    useEffect(() => {
        if (!sessionStorage.getItem("access_token")) {
            navigate("/");
        } else {

            api.getSheet(sessionStorage.getItem('access_token'), configuration.sheetName4).then((data) => {
                if (data.name == "AxiosError") {
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    //console.log("AXIOS data:", data);
                    setTableData4(data);

                    let confirmedRowQ1 = 0;
                    let forecastRowQ1 = 0;
                    let confirmedRowQ2 = 0;
                    let forecastRowQ2 = 0;
                    let confirmedRowQ3 = 0;
                    let forecastRowQ3 = 0;
                    let confirmedRowQ4 = 0;
                    let forecastRowQ4 = 0;

                    data.map((item) => {
                        
                        if (removeSpaceToLower(item[1]) === "totalconfirmedq1") {
                            confirmedRowQ1 = data.indexOf(item);
                        }
                        if (removeSpaceToLower(item[1]) === "totalforecastq1") {
                            forecastRowQ1 = data.indexOf(item);
                        }
                        if (removeSpaceToLower(item[1]) === "totalconfirmedq2") {
                            confirmedRowQ2 = data.indexOf(item);
                        }
                        if (removeSpaceToLower(item[1]) === "totalforecastq2") {
                            forecastRowQ2 = data.indexOf(item);
                        }
                        if (removeSpaceToLower(item[1]) === "totalconfirmedq3") {
                            confirmedRowQ3 = data.indexOf(item);
                        }
                        if (removeSpaceToLower(item[1]) === "totalforecastq3") {
                            forecastRowQ3 = data.indexOf(item);
                        }
                        if (removeSpaceToLower(item[1]) === "totalconfirmedq4") {
                            confirmedRowQ4 = data.indexOf(item);
                        }
                        if (removeSpaceToLower(item[1]) === "totalforecastq4") {
                            forecastRowQ4 = data.indexOf(item);
                        }

                    });

                    setQtrData4([
                        { qtr: "Q1", months: ["January", "February", "March"], "confirmedRow": confirmedRowQ1, "forecastRow": forecastRowQ1 },
                        { qtr: "Q2", months: ["April", "May", "June"], "confirmedRow": confirmedRowQ2, "forecastRow": forecastRowQ2 },
                        { qtr: "Q3", months: ["July", "August", "September"], "confirmedRow": confirmedRowQ3, "forecastRow": forecastRowQ3 },
                        { qtr: "Q4", months: ["October", "November", "December"], "confirmedRow": confirmedRowQ4, "forecastRow": forecastRowQ4 }
                    ]);
                }
            });
        }
    }, []);

    useEffect(() => {
        const d = new Date();
        setCurrentYear(d.getFullYear());
        qtrData4.map((item) => {
            if (item.months.includes(d.toLocaleString('en-us', { month: 'long' }))) {
                setCurrentQtr({
                    "name": item.qtr,
                    "number": qtrData4.findIndex((obj) => obj === item)
                });
                console.log(item.qtr);
            }
        });
    }, [qtrData4]);


    useEffect(() => {
        if (tableData4.length && qtrData4.length && CurrentQtr) {

            var qtrDataState = [];
            var currentQtrDataState = [];

            qtrData4.map((data) => {

                //console.log("Forecast", dataFormat(tableData4[data.forecastRow][11]));
                //REV        
                let targetTotal_REV = dataFormat(tableData4[data.forecastRow][11]);
                let confirmedTotal_REV = dataFormat(tableData4[data.confirmedRow][11]);
                let varianceTotal_REV = targetTotal_REV - confirmedTotal_REV;
                varianceTotal_REV = varianceTotal_REV.toFixed(2);
                let confirmedPercentageTotal_REV = Math.round(confirmedTotal_REV * 100 / targetTotal_REV);
                //-

                //qtrDataState.push({
                qtrDataState.push({
                    qtr: data.qtr,

                    //REV
                    targetTotal_REV: targetTotal_REV,
                    confirmedTotal_REV: confirmedTotal_REV,
                    varianceTotal_REV: varianceTotal_REV,
                    confirmedPercentageTotal_REV: confirmedPercentageTotal_REV,
                    //-
                })
                if (CurrentQtr.name === data.qtr) {
                    currentQtrDataState.push({
                        qtr: data.qtr,

                        //REV
                        targetTotal_REV: targetTotal_REV,
                        confirmedTotal_REV: confirmedTotal_REV,
                        varianceTotal_REV: varianceTotal_REV,
                        confirmedPercentageTotal_REV: confirmedPercentageTotal_REV,
                        //-
                    })
                }
            });

            setDataState(qtrDataState);
            setCurrentQtrData(currentQtrDataState);
        }
    }, [tableData4, CurrentQtr, qtrData4])

    useEffect(() => {

        if (CurrentQtrData && CurrentQtr) {
            console.log("CurrentQtrData: ", CurrentQtrData);
            console.log("Data State: ", dataState);

            const sumConfirmedTotal_REV = dataState.slice(0, 4).map((item) => {
                return +item.confirmedTotal_REV;
            });
            const sumTargetTotal_REV = dataState.slice(0, 4).map((item) => {
                return +item.targetTotal_REV;
            });

            const confirmedTotalStart_REV = dataState[0]?.confirmedTotal_REV;
            const confirmedTotalEnd_REV = sumConfirmedTotal_REV.reduce((partialSum, a) => partialSum + a, 0);
            const targetTotalStart_REV = dataState[0]?.targetTotal_REV;
            const targetTotalEnd_REV = sumTargetTotal_REV.reduce((partialSum, a) => partialSum + a, 0);


            //Total Revenue LineChart
            setYearLineChartData_REV({
                //labels: [dataState[0]?.qtr, dataState[CurrentQtr.number]?.qtr],
                //labels: dataState.slice(0, 4).map((item) => {
                labels: dataState.slice(0, 2).map((item) => {
                    var shortMonthName = item.qtr;
                    return shortMonthName;
                }),
                datasets: [
                    {
                        label: "Target",
                        data: dataState.slice(0, 4).map((item) => item.targetTotal_REV),
                        fill: true,
                        borderColor: colours.target,
                        backgroundColor: colours.targetTint
                    },
                    {
                        label: "Confirmed",
                        data: dataState.slice(0, 4).map((item) => item.confirmedTotal_REV),
                        fill: true,
                        borderColor: colours.confirmed,
                        backgroundColor: colours.confirmedTint
                    }
                ]
            });
            //-

            //Sum Total Revenue LineChart
            setSumYearLineChartData_REV({
                //labels: [dataState[0]?.qtr, dataState[3]?.qtr],
                labels: [dataState[0]?.qtr, dataState[1]?.qtr],
                datasets: [
                    {
                        label: "Target",
                        data: [targetTotalStart_REV, targetTotalEnd_REV],
                        fill: true,
                        borderColor: colours.target,
                        backgroundColor: colours.targetTint
                    },
                    {
                        label: "Confirmed",
                        data: [confirmedTotalStart_REV, confirmedTotalEnd_REV],
                        fill: true,
                        borderColor: colours.confirmed,
                        backgroundColor: colours.confirmedTint
                    }
                ]
            });
            //-


            //Totals REV Doughnut
            var DnLabels_REV = ["Variance", "Confirmed"];
            var DnData_REV = [CurrentQtrData[0]?.varianceTotal_REV, CurrentQtrData[0]?.confirmedTotal_REV];
            var DnBorderColors_REV = ['#0FFA00'];
            var DnFillColors_REV = ['#0FFA00', '#313640'];
            if (CurrentQtrData[0]?.varianceTotal_REV < 0) {
                DnFillColors_REV = ['#04CF35', '#0FFA00'];
            }

            setDnDataTotal_REV({
                labels: CurrentQtrData[0]?.varianceTotal_REV < 0 ? DnLabels_REV : DnLabels_REV.reverse(),
                datasets: [{
                    label: "target",
                    data: CurrentQtrData[0]?.varianceTotal_REV < 0 ? DnData_REV : DnData_REV.reverse(),
                    fill: true,
                    borderColor: DnBorderColors_REV,
                    backgroundColor: DnFillColors_REV,
                    weight: 20
                }]
            });

            setDnOptionsTotal_REV({
                cutout: "80%",
                plugins: {
                    legend: {
                        display: false
                    },
                    doughnutlabel: {
                        color: '#fff',
                        labels: [{
                            text: CurrentQtrData[0]?.confirmedPercentageTotal_REV + '%',
                            font: {
                                size: toString(CurrentQtrData[0]?.confirmedPercentagePT_REV).length * 2,
                                weight: 'bold'
                            }
                        }, {
                            text: 'of target',
                            font: {
                                size: 15
                            }
                        }]
                    }
                }
            });
            //-
        }

    }, [dataState, CurrentQtr, CurrentQtrData]);

    const lineOptions = {
        radius: 5,
        color: "white",
        legend: {
            labels: {
                padding: 40
            }
        },
        scales: {
            x: {
                ticks: {
                    color: "white"
                },
                grid: {
                    color: "#313640"
                }
            },
            y: {
                ticks: {
                    color: "white"
                },
                grid: {
                    color: "#313640"
                }
            }
        }
    };

    function handleRefresh() {
        window.location.reload();
        return true;
    }

    return (
        <>
            <PullToRefresh onRefresh={handleRefresh} className="pullRefresh">
                <div style={{ height: "100%" }}>
                    {!dataState.length && <Loader />}
                    <div className='Section Single'>
                        {/* <h3 className='SectionTitle'>Sales By Revenue</h3> */}
                        <div className='CardsContainer'>
                            <div className='CardWrapper CardWrapperMain Doughnut'>
                                <div className='Card'>
                                    <div className='Card-header'><strong>Revenue by Date of Travel:</strong><span>{CurrentQtrData[0]?.qtr}</span></div>
                                    <div className='Card-content'>
                                        <div className='Chart'>
                                            {dataState.length && <DoughnutChart chartData={dnDataTotal_REV} options={dnOptionsTotal_REV} />}
                                        </div>
                                        <div className='Labels'>
                                            <p><b>Target:</b> {numberWithCommas(CurrentQtrData[0]?.targetTotal_REV)}</p>
                                            <p><b>Confirmed:</b> {numberWithCommas(CurrentQtrData[0]?.confirmedTotal_REV)}</p>
                                            <p><b>Variance:</b> {numberWithCommas(CurrentQtrData[0]?.varianceTotal_REV)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Section Single'>
                        <div className='CardWrapper CardWrapperMain LineChart'>
                            <div className='Card'>
                                <div className='Card-header'><strong>Revenue by Date of Travel:</strong><span>{currentYear}</span></div>
                                <div className='Card-content'>
                                    {dataState.length && <LineChart chartData={yearLineChartData_REV} options={lineOptions} />}
                                </div>
                            </div>
                        </div>
                        <div className='CardWrapper CardWrapperMain LineChart'>
                            <div className='Card'>
                                <div className='Card-header'><strong>Revenue by Date of Travel:</strong><span>{currentYear}</span></div>
                                <div className='Card-content'>
                                    {dataState.length && <LineChart chartData={sumYearLineChartData_REV} options={lineOptions} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    {CurrentQtr &&
                        <div className='BottomNav QtrsNav'>
                            <div className='ButtonGroup'>
                                <button className={CurrentQtr.name === "Q1" ? 'active' : ''}
                                    onClick={() => {
                                        setCurrentQtr({
                                            "name": "Q1",
                                            "number": 0
                                        });
                                    }}
                                >Q1</button>
                                <button className={CurrentQtr.name === "Q2" ? 'active' : ''}
                                    onClick={() => {
                                        setCurrentQtr({
                                            "name": "Q2",
                                            "number": 1
                                        });
                                    }}
                                >Q2</button>
                                {/* <button className={CurrentQtr.name === "Q3" ? 'active' : ''}
                                    onClick={() => {
                                        setCurrentQtr({
                                            "name": "Q3",
                                            "number": 2
                                        });
                                    }}
                                >Q3</button>
                                <button className={CurrentQtr.name === "Q4" ? 'active' : ''}
                                    onClick={() => {
                                        setCurrentQtr({
                                            "name": "Q4",
                                            "number": 3
                                        });
                                    }}
                                >Q4</button> */}
                            </div>
                        </div>
                    }
                </div>
            </PullToRefresh>
        </>
    )
}

export default QuarterlyTargetsTravelDate