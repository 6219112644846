import { useState } from 'react';
import { Chart, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS } from "chart.js/auto";
//import ChartDataLabels from 'chartjs-plugin-datalabels';
import DoughnutLabel from 'chartjs-plugin-doughnutlabel-rebourne';

function DoughnutChart({ chartData, options, plugins }) {
    return (
        <Doughnut
            data ={ chartData }
            options = { options }
            plugins = {[DoughnutLabel]}
        />
    )
}

export default DoughnutChart;