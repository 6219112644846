import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from "chart.js/auto";
import plugin from 'chartjs-plugin-datalabels';

const plugins = {
	beforeInit(chart) {
		// Get reference to the original fit function
		const originalFit = chart.legend.fit;

		// Override the fit function
		chart.legend.fit = function fit() {
			// Call original function and bind scope in order to use `this` correctly inside it
			originalFit.bind(chart.legend)();
			// Change the height as suggested in another answers
			this.height += 20;
		}
	}
}


function LineChart({ chartData, options }) {
    return (
        <Line
            data ={ chartData }
            options = { options }
            height = {"220"}
            plugins = {[plugins]}
        />
    )
}

export default LineChart;