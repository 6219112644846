import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Bars3Icon } from '@heroicons/react/20/solid'
import { useNavigate, useLocation } from "react-router-dom";

export default function DropDownMenu(props) {

  const location = useLocation();
  const { pathname } = location;

  let navigate = useNavigate(); 

  return (
      <Menu as="div" className="relative inline-block text-left">
        <div className='flex items-center'>
          <Menu.Button>
            <Bars3Icon
              className="h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-[-12px] mt-7 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-slate-800 shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1 pb-0 ">
              {props.menuItems.map((item) => (
                <Menu.Item className="mb-1" key={item.pathname}>
                {() => (
                  <button
                    className={`${
                      item.pathname === pathname ? 'bg-slate-700 text-white' : 'text-white'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-gray-900`}
                  onClick={()=>{
                    navigate(item.pathname);
                  }}
                  >
                    {item.icon} {item.label}
                  </button>
                )}
              </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
  )
}
