import { createGlobalState } from "react-hooks-global-state";


const initialState = {
    tableData3: [],
    tableData4: []
};

const {setGlobalState, useGlobalState} = createGlobalState(initialState);

export {setGlobalState, useGlobalState};