import React from 'react'
import EpicLogo from '../images/epic-dash-logo-white.svg'

import { CalendarDaysIcon, ChartPieIcon, ArrowLeftEndOnRectangleIcon, GlobeEuropeAfricaIcon } from '@heroicons/react/24/solid'
import DropDownMenu from './DropDownMenu'

function NavBar() {

  const menuItems = [
    { "pathname": "/dashboard", "label": "Month", "icon": <CalendarDaysIcon className="mr-2 h-5 w-5 text-white" aria-hidden="true" /> },
    { "pathname": "/quarterly", "label": "Quarter", "icon": <ChartPieIcon className="mr-2 h-5 w-5 text-white" aria-hidden="true" /> },
    { "pathname": "/quarterly-travel-date", "label": "Quarter Travel Date", "icon": <GlobeEuropeAfricaIcon className="mr-2 h-5 w-5 text-white" aria-hidden="true" /> },
    { "pathname": "/logout", "label": "Logout", "icon": <ArrowLeftEndOnRectangleIcon className="mr-2 h-5 w-5 text-white" aria-hidden="true" /> }
  ]

  return (
    <>
      <nav className='Navbar flex justify-between items-center'>
        <div>
          <img className='Logo' src={EpicLogo} />
        </div>
        <DropDownMenu menuItems={menuItems} />
      </nav>
    </>
  )
}

export default NavBar