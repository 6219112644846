import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function LogoutPage() {
    let navigate = useNavigate();
    useEffect(() => {
        sessionStorage.clear();
        navigate("/");
    });
    return (
        <>
        </>
    )
}

export default LogoutPage