//import GoogleLogin from "react-google-login";
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import * as api from "../api";
import { setGlobalState, useGlobalState } from '../globalState';
import { useNavigate } from 'react-router-dom';
import GoogleButton from '../images/btn_google_signin_dark_normal_web@2x.png';

function Login(){

    
    

    let navigate = useNavigate();

    

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            console.log(codeResponse);
            api.getAccessToken(codeResponse.code).then((res) => {
                console.log(res);
                sessionStorage.setItem("access_token", res);
                navigate('/dashboard');
            });   
        },
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/spreadsheets.readonly'
      });
    return(
        <div id="signInButton" style={{marginTop: "40px"}}>
            {/* <button onClick={()=> login()}>Login</button> */}
            <a href='#' onClick={(e)=>{e.preventDefault(); login(); }}><img src={GoogleButton} alt="Sign in with Google" width={"191px"} /></a>
        </div>
    );
}

export default Login;